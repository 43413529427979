// @ts-nocheck

import React from 'react';
import { Link, useIntl, injectIntl } from 'gatsby-plugin-intl';
import parse from 'html-react-parser';
import styled from 'styled-components';
import 'animate.css/animate.min.css';
import { Row, Col, Table } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Title from '../components/Title';
import Button from '../components/Button';
import heroImage from '../images/banner-1.jpg';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  color: #004165;
  font-size: ${({ size }) => size ? `${size}em` : '2em'};
  font-weight: bold;
  margin-bottom: 1em;
`;

const ParagraphContainer = styled(Col)`
  padding: 0.5em;
  & li {
    padding-bottom: 0.5em;
  }
`;

const ListContainer = styled.ul``;

const ProgramAndPricing = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="Program and Pricing" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="15% center"
          text={intl.formatMessage({ id: 'program.hero_text' })}
          centered
        />
        <Row>
          <Col
            xxl={6}
            xl={6}
            lg={8}
            md={10}
            sm={12}
            className="mx-auto pt-5"
            style={{ padding: '0px' }}
          >
            <Button mb={1.5} to="/apply">Apply Now</Button>
            <Heading>
              {intl.formatMessage({ id: 'program.title' })}
            </Heading>
            <ParagraphContainer>
              {parse(intl.formatMessage({ id: 'program.paragraph_1' }))}
            </ParagraphContainer>
            <ParagraphContainer>
              <p>{intl.formatMessage({ id: 'program.inclusions' })}</p>
              <ul>
                {parse(intl.formatMessage({ id: 'program.inclusion_options' }))}
              </ul>
            </ParagraphContainer>
            <ParagraphContainer>
              <p>{intl.formatMessage({ id: 'program.add_ons' })}</p>
              <ul>
                {parse(intl.formatMessage({ id: 'program.add_on_options' }))}
              </ul>
            </ParagraphContainer>
            <ParagraphContainer>
              <Heading>
                {intl.formatMessage({ id: 'program.title_1' })}
              </Heading>
              <Heading size={1.5}>
                {intl.formatMessage({ id: 'program.subtitle_1' })}
              </Heading>
              {parse(intl.formatMessage({ id: 'program.paragraph_2' }))}
              <Heading size={1.5}>
                {intl.formatMessage({ id: 'program.subtitle_2' })}
              </Heading>
              {parse(intl.formatMessage({ id: 'program.paragraph_3_1' }))}
              {parse(intl.formatMessage({ id: 'program.paragraph_3_2' }))}
              {parse(intl.formatMessage({ id: 'program.paragraph_3_3' }))}
              <Heading size={1.5}>
                {intl.formatMessage({ id: 'program.subtitle_3' })}
              </Heading>
              {parse(intl.formatMessage({ id: 'program.paragraph_4' }))}
            </ParagraphContainer>
            <ParagraphContainer>
              <Heading>
                {intl.formatMessage({ id: 'program.package_pricing.title' })}
              </Heading>
              <Table className="pricing-table" striped responsive>
                <thead>
                  <tr>
                    <th>Package</th>
                    <th>Duration</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_1.title' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_1.duration' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_1.cost' }))}
                  </tr>
                  <tr>
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_2.title' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_2.duration' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_2.cost' }))}
                  </tr>
                  <tr>
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_3.title' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_3.duration' }))}
                    {parse(intl.formatMessage({ id: 'program.package_pricing.package_3.cost' }))}
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      {parse(intl.formatMessage({ id: 'program.package_pricing.program_fee.title' }))}
                      {parse(intl.formatMessage({ id: 'program.package_pricing.program_fee.footnote' }))}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </ParagraphContainer>
            <Button mb={1.5} mt={1} to="/apply">Apply Now</Button>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(ProgramAndPricing);
