import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

const Button = styled(Link)`
  font-size: 1.2rem;
  border: 1px solid black;
  color: black;
  text-align: center;
  padding: 0.6rem 1em;
  letter-spacing: 0.5px;
  margin: auto;
  display: block;
  width: 150px;
  margin-top: ${({ mt }) => mt && `${mt}em`};
  margin-bottom: ${({ mb }) => mb && `${mb}em`};
  text-decoration: underline;
  &:hover {
    color: black;
    text-decoration: none;
  }
`;

export default Button
